@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.gametile {
    --border-width-small: calc(var(--cell-size) * .03);
    --border-width-large: calc(var(--cell-size) * .05);

    height: var(--cell-size);
    width: var(--cell-size);

    --blue-base: lightskyblue;
    --red-base: orangered;

    border-radius: 50%;
}

/* NUMBER STYLE */
.gametile--fixed-color.gametile--color-blue .gametile__number {
    color: white;
}

.gametile {
    transition: background-color 80ms ease-in-out;
    /*align-content: center;*/
}

.gametile__number {
    transition: color 80ms ease-in-out;
}

.gametile__number {
    font-family: 'Fredoka One', cursive;
    text-align: center;
    font-size: calc(var(--cell-size) * .8);
}

/* TILE COLORING */
.gametile--color-blue {
    background-color: var(--blue-base);
}

.gametile--variable-color.gametile--color-blue {
    color: var(--blue-base);
}

.gametile--color-red {
    background-color: var(--red-base);
    color: var(--red-base);
}

.gametile--variable-color.gametile--color-red {
}

.gametile--color-unknown {
    background-color: white;
}

.gametile--variable-color.gametile--color-unknown {
    color: white;
}

.gametile--fixed-color {
    border: solid black;
    border-width: var(--border-width-large);
}

.gametile--variable-color {
    border: solid gray;
    border-width: var(--border-width-small);
}

.gametile--variable-color:hover {
    border-color: #9e9d9d;
    cursor: pointer;
}

/* SATISFACTION STATES */
.gametile--satisfactionstate__Satisfied, .gametile--satisfactionstate__RightNumberButUnterminated {
    border-color: green;
}

.gametile--satisfactionstate__TooFew {

}

.gametile--satisfactionstate__TooMany {
    border-color: red;

}
