@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
.header-panel {
    height: 60px;
    background-color: lightgoldenrodyellow;
    padding: 10px;
    display: flex;
    flex-direction: row;

}

.stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.size-input {
    width: 2em;
}

.app {
  /*text-align: center;*/
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.gameboard__outer {

    display: flex;
    flex-direction: row;
    justify-content: center;

    /*background-color: lightpink;*/
}

.gameboard {
    /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.gameboard {
    /*--rows: 'set in js';*/
    /*--cols: 'set in js';*/
    /*--cell-sizepx: 'set in js';*/
    --cell-size: calc(var(--cell-sizepx) * .95px);
    --gap: calc(var(--cell-sizepx) * .05px);

    padding: 20px;

    display: grid;

    grid-template-rows: repeat(var(--rows), var(--cell-size));
    grid-template-columns: repeat(var(--cols), var(--cell-size));
    grid-row-gap: var(--gap);
    row-gap: var(--gap);
    grid-column-gap: var(--gap);
    -webkit-column-gap: var(--gap);
            column-gap: var(--gap);


}

.gameboard--incomplete {
    background-color: white;
}

.gameboard--complete {
    background-color: lightgreen;
}
.gametile {
    --border-width-small: calc(var(--cell-size) * .03);
    --border-width-large: calc(var(--cell-size) * .05);

    height: var(--cell-size);
    width: var(--cell-size);

    --blue-base: lightskyblue;
    --red-base: orangered;

    border-radius: 50%;
}

/* NUMBER STYLE */
.gametile--fixed-color.gametile--color-blue .gametile__number {
    color: white;
}

.gametile {
    transition: background-color 80ms ease-in-out;
    /*align-content: center;*/
}

.gametile__number {
    transition: color 80ms ease-in-out;
}

.gametile__number {
    font-family: 'Fredoka One', cursive;
    text-align: center;
    font-size: calc(var(--cell-size) * .8);
}

/* TILE COLORING */
.gametile--color-blue {
    background-color: var(--blue-base);
}

.gametile--variable-color.gametile--color-blue {
    color: var(--blue-base);
}

.gametile--color-red {
    background-color: var(--red-base);
    color: var(--red-base);
}

.gametile--variable-color.gametile--color-red {
}

.gametile--color-unknown {
    background-color: white;
}

.gametile--variable-color.gametile--color-unknown {
    color: white;
}

.gametile--fixed-color {
    border: solid black;
    border-width: var(--border-width-large);
}

.gametile--variable-color {
    border: solid gray;
    border-width: var(--border-width-small);
}

.gametile--variable-color:hover {
    border-color: #9e9d9d;
    cursor: pointer;
}

/* SATISFACTION STATES */
.gametile--satisfactionstate__Satisfied, .gametile--satisfactionstate__RightNumberButUnterminated {
    border-color: green;
}

.gametile--satisfactionstate__TooFew {

}

.gametile--satisfactionstate__TooMany {
    border-color: red;

}

